<template>
    <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
        <div class="inner">
            <p class="eula">
                <b>myneo Privacy Policy Statement</b><br />
                <b>Effective Date: Jul 14, 2023</b><br /><br />
                The content of this privacy policy applies to the functions and services provided by the mobile client myneo
                of DynEd Language Software System Development (Beijing) Co., Ltd.

                <br /><br /><b>myneo Privacy Policy</b><br /><br />
                The Privacy Policy will address myneo's collection and use of your personal information at any time when you
                interact with us, for example: when you visit our website, when you use myneo products and services, or when
                you contact our sales representatives. In order to realize business functions, the end user authorizes and
                agrees to enable "reading and writing SD card permissions", "network access permissions", "microphone
                permissions" or "recording audio permissions", and "front camera permissions". If the end user does not want
                the above information to be collected, it can be achieved by turning off the "read and write SD card
                permission", "network access permission", "microphone permission", and "front camera permission".<br />
                Disclosure of user information obtained by third-party SDK or related technologies:
                <br><br />
            <ul style="margin-left:18px;">
                <li style="list-style-type: number;">Bugsnag collection is used to collect myneo error information on
                    Android and iOS. It is necessary to collect the following information when an event occurs: battery
                    level on the device, device brand, whether the device is charging, the screen density ratio information
                    of the device in DPI, and whether the device is an emulator, device location services, the device's
                    current network type, and the device's screen resolution.</li>
                <li style="list-style-type: number;">Baidu baidu push SDK provides Baidu push notification service to
                    collect device attribute information (hardware model, operating system version and system status,
                    Android ID (Android only), device connection information (such as telecom operators, WIFI information)).
                    The privacy agreement link of Baidu push sdk https://push.baidu.com/doc/guide/baidu_legal.</li>
                <li style="list-style-type: number;">The Agora SDK provides video call services, and may collect microphone
                    and camera information, network type, user attributes, channel attributes, and other information during
                    use. Agora Privacy Compliance Link https://www.agora.io/en/privacy -policy.</li>
            </ul>
            
            <br /><b>Why do we collect personal information?</b><br /><br />
            myneo collects personal information to improve our services and support. In addition, your personal information
            can help us keep you informed about product notifications, product updates and promotional activities. If you
            have subscribed to myneo's e-mail, myneo will send you the latest product and service information regularly. You
            can also unsubscribe at any time. <br>

            <br /><b>What personal information do we collect?</b><br /><br />
            Your personal information helps us improve our services in a number of ways. Therefore, we may collect relevant
            personal information, such as your name, phone number, email address, product purchase date and other
            information related to the myneo products you use, as well as information related to support or services. <br>

            <br /><b>Will we disclose your personal information?</b><br /><br />
            myneo respects your privacy very much. When you use myneo's products or services, you give us your information.
            We will not share your personal information with any company, organization or individual other than myneo,
            unless we have your permission or are required by relevant laws to disclose it.


            <br /><br /><b>How do we protect your personal information?</b><br /><br />
            myneo protects your personal information by taking precautionary measures (including administrative/technical
            measures and physical security measures) to prevent your personal information from being lost, stolen, misused
            or illegally accessed, disclosed, altered or destroyed.

            <br /><br /><b>Cancellation of personal information</b><br /><br />
            We retain a minimum of personal information to allow access to our services and to verify certificates obtained. If you wish to "opt-out" and have your personally identifiable information removed from our database, you may send a request to <a href="mailto:legal@nexgenenglishonline.co" style="color:#49c5fe;">legal@nexgenenglishonline.co</a> requesting deletion of your personal information, stating that "I have read, understand and agree and request the deletion of Personally Identifiable Information as stated in the Privacy Policy." <b>You can also request Account Deletion through in app side menu > Settings > Account Deletion Request</b>. Unless we need to retain your personally identifiable information to comply with applicable legal obligations or resolve a dispute, we will generally delete your personally identifiable information within <b>60 business days (or as the laws allows)</b> of receiving your request. If you request removal of your personally identifiable information before course completion, you will not be able to continue your studies. Additionally, if you have already received a Neo certificate and you later request that your personally identifiable information be removed, we will not be able to process any subsequent certificate verification requests because we will no longer have your information.

            <br /><br /><b>Privacy Issues</b><br /><br />
            If you have any questions about our customer privacy policy or data processing, please email
            <b>legal@nexgenenglishonline.co</b>
            </p>
        </div>
    </section>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>